<template>
  <div class="container">
    <h1>Lista de Indicadores</h1>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col cols="3">
            <b-input-group>
              <b-form-input
                v-model="termo_pesquisa"
                @keyup.enter="findTerm"
                placeholder="Buscar indicador ..."
              />
              <b-input-group-append>
                <b-button variant="primary" size="sm" @click="findTerm">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <div class="d-flex">
          <div class="pa-2 flex-grow-1"></div>
          <div class="pa-2">
            <router-link
              v-if="$can('indicator:create')"
              :to="paramsToNewIndicator"
              class="mb-2 ml-4 btn btn-outline-primary link-edit"
            >
              Novo
              <b-icon
                icon="plus-circle"
                aria-hidden="true"
                class="mx-auto my-auto pl-1"
              ></b-icon
            ></router-link>
          </div>
        </div>

        <b-table striped hover :items="indicators" :fields="fields">
          <template #cell(updated_at)="row">
            {{ row.item.updated_at | format_date }}
          </template>
          <template #cell(actions)="row">
            <Actions
              :item="row.item"
              :view_show="false"
              :view_edit="$can('indicator:edit')"
              :view_delete="$can('indicator:delete')"
              edit_to="indicators_edit"
              @delete="delete_indicator"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Actions from "@/components/Table/Actions.vue";

export default {
  components: { Actions },
  data() {
    return {
      page: 1,
      termo_pesquisa: "",
    };
  },
  computed: {
    ...mapState("indicators", ["indicators", "pagination"]),
    fields() {
      return [
        { key: "name", label: "Nome" },
        { key: "initials", label: "Sigla" },
        { key: "actions", label: "#", class: "col-1" },
      ];
    },
    paramsToNewIndicator() {
      return {
        name: "indicators_create",
      };
    },
  },
  methods: {
    ...mapActions("indicators", ["ActionGetAll", "ActionDelete"]),
    async delete_indicator(indicator) {
      try {
        await this.ActionDelete(indicator.id);
        this.$notifications.addNotification({
          message: "Registro deletado com sucesso",
          type: "success",
        });
        await this.ActionGetAll({});
      } catch (error) {
        this.$notifications.addNotification({
          message: "Erro ao excluir registro",
          type: "danger",
        });
      }
    },

    linkGen(page) {
      return {
        query: { q: this.termo_pesquisa, page },
      };
    },

    findTerm() {
      this.page = 1;
      this.loadData();
    },

    async loadData() {
      try {
        await this.ActionGetAll({
          page: this.page,
          q: this.termo_pesquisa,
        });
      } catch (error) {
        this.$notifications.addNotification({
          message: "Erro ao buscar termo.",
          type: "danger",
        });
      }
    },
  },
  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }

    if (this.$route.query.q) {
      this.termo_pesquisa = this.$route.query.q;
    }

    await this.loadData();
  },
};
</script>

<style></style>
